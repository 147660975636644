import posthog from "posthog-js";

export default {
  install(Vue) {
    // NOTE: PostHog app keys found on (a) Project Settings
    // or (b) posthog > docs > "Install PostHog"

    const posthogApiKey =
      process.env.VUE_APP_ENVIRONMENT === "production"
        ? "phc_4ZU3y3kDDmmjhTJMK3lUHqjsUYj8IochTBoUwrFFQC7"
        : "phc_IHoZPSpG4WmOZNGPSDBM8BslIIWaBskOwo3jLgbPM7K";

    posthog.init(posthogApiKey, {
      api_host: "https://us.i.posthog.com",
      // disable_session_recording: true,
    });

    Vue.prototype.$posthog = posthog;
  },
};
